// noinspection JSVoidFunctionReturnValueUsed,JSFunctionExpressionToArrowFunction

let clickedMenuItem = false;

const customSelect = (function($){

    $(window).on('load', function(){
        $("body").addClass("load-done");
    });

    $(document).scroll(function(e){
    });

    $(window).resize(function(){

    });

    $(".custom-select .select-header").click(function (e) {
        e.stopPropagation();
        var thisParent = $(this).parent();
        var thisHeader = $(this);
        var thisbody = thisParent.find(".select-body");

        if (thisParent.hasClass("open-select")) {
            thisParent.removeClass("open-select");
        }
        else {
            $(".custom-select").removeClass("open-select");
            $(".language-bar").removeClass("open-languages");
            thisParent.addClass("open-select");
        }

    });

    $(".custom-select .select-body .item").click(function (e) {
        e.stopPropagation();

        var thisParent = $(this).parent().parent();
        var thisEl = $(this);
        var thisElInput = $(this).parent().parent().find("input");
        var thisElHeader = $(this).parent().parent().find(".select-header");
        thisElValue = thisEl.attr("data-value");
        thisElHeader.text(thisElValue);
        thisParent.removeClass("open-select");

        if (thisElInput.length) {
            thisElInput.attr("value", thisElValue);
            thisElInput.trigger('change');
        }
    });

    $(window).click(function () {
        $(".custom-select").removeClass("open-select");
    });

})(jQuery);

const mainNav = (function($){
    const mainNav = $(".main-nav");
    const windowWidth = $(document).width();
    const thirdLevel = mainNav.find('li.has-third-level-nav');

    function menuEvent() {
        if(windowWidth > 992) {
            mainNav.find("li").hover(function () {
                if(!$(this).hasClass('has-third-level-nav') && !$(this).closest('.has-third-level-nav').length) {
                    thirdLevel.addClass('hide-sub-menu');
                    thirdLevel.find('ul').addClass('current-menu-item');
                    $(this).addClass('current-menu-item');
                }

            }, function () {
                $(this).removeClass('current-menu-item');
                thirdLevel.removeClass('hide-sub-menu');
            });
        } else {
            mainNav.find(".plus").click(function () {
               //$(this).closest('li').toggleClass('current-menu-item');

               var thisElParent = $(this).closest('li');

               if(thisElParent.hasClass('current-menu-item')) {
                   thisElParent.removeClass('current-menu-item');
               } else {
                    mainNav.find("li").removeClass('current-menu-item');
                    thisElParent.addClass('current-menu-item');
               }
            });

            thirdLevel.removeClass('has-third-level-nav');
        }
    }

    mainNav.find('li').each(function() {
        const thisEl = $(this);
        (thisEl.find('ul').length > 0) ? thisEl.addClass('has-sub').append('<span class="plus"></span>') : "";
    });

    menuEvent();

    $(window).resize(function(){
        //menuEvent();
    });

})(jQuery);


const secNav = (function($){
    const secNav = $(".secondary-nav");
    const windowWidth = $(document).width();

    function menuEvent() {
        if(windowWidth > 992) {
            secNav.find("li").hover(function () {
                $(this).addClass('current-menu-item');
            }, function () {
                $(this).removeClass('current-menu-item');
            });
        } else {
            secNav.find(".plus").click(function () {
                var thisElParent = $(this).closest('li');

                if(thisElParent.hasClass('current-menu-item')) {
                    thisElParent.removeClass('current-menu-item');
                } else {
                    secNav.find("li").removeClass('current-menu-item');
                    thisElParent.addClass('current-menu-item');
                }
            });
        }
    }

    menuEvent();

    $(window).resize(function(){
        menuEvent();
    });

})(jQuery);


const searchBar = (function($){
    const wrapForm = $(".wrap-search-form");
    const closeResults = wrapForm.find('.close-search');

    wrapForm.on('focus click', 'input',  function(e){
        e.stopPropagation();
        wrapForm.addClass('search-form-active');
    });

    wrapForm.on('keyup', 'input',  function(e){
        e.stopPropagation();
        if($(this).val() != "") {
            wrapForm.addClass('search-results-active');
        } else {
            wrapForm.removeClass('search-results-active');
        }
    });

    wrapForm.find('.search-results').click(function(e) {
        e.stopPropagation();
    });

    closeResults.click(function(){
        wrapForm.removeClass('search-results-active search-form-active');
        wrapForm.find('input[type=text]').val("");
    })

    // $(window).click(function() {
    //     wrapForm.removeClass('search-results-active search-form-active');
    //     wrapForm.find('input[type=text]').val("");
    // });
    $(document).on("click", function (event) {
        // If the target is not the container or a child of the container, then process
        // the click event for outside of the container.
        if ($(event.target).closest(".search-results").length === 0) {
            wrapForm.removeClass('search-results-active search-form-active');
            wrapForm.find('input[type=text]').val("");
        }
    });

})(jQuery);


// const scrollUp = (function($){
//     let lastScrollTop = 0;
//     const windowWidth = $(document).width();
//     let delay = 500;
//     let timeout = null;
//
//     $(window).scroll(function(event) {
//         var st = $(this).scrollTop();
//
//         // if (st > lastScrollTop && st != 0 && st > 0) {
//         //     $('body').addClass("hide-header")
//         // } else {
//         //     (!clickedMenuItem) ? $('body').removeClass("hide-header") : "";
//         // }
//
//         if(windowWidth > 992 && st >= 300) {
//             $('body').addClass("sticky-header");
//         } else if(windowWidth <= 992 && st >= 150) {
//             $('body').addClass("sticky-header");
//         } else {
//             $('body').removeClass("sticky-header");
//         }
//
//         lastScrollTop = st;
//
//
//         // var fromTop = $(document).scrollTop();
//
//         // if(fromTop > 400){
//         //     $("body").addClass("hide-header");
//         // }
//         // else{
//         //     $("body").removeClass("hide-header");
//         // }
//
//         clearTimeout(timeout);
//         timeout = setTimeout(function(){
//             clickedMenuItem = false;
//         },delay);
//     });
//
// })(jQuery);


const mixedSlider = (function($){

    $('.content-slider').slick({
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    arrows: true
                }
            }
        ]
    });

})(jQuery);


const mixedSliderArrows = (function($){

    $('.content-slider-arrows').slick({
        infinite: true,
        speed: 300,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    arrows: true
                }
            }
        ]
    });

})(jQuery);


const  sliderWithNav = (function($){

    $('.slider-grid-col').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        asNavFor: '.slider-navigation',
        adaptiveHeight: true
    });

    $('.slider-navigation').slick({
        asNavFor: '.slider-grid-col',
        infinite: false,
        dots: false,
        arrows: false,
        centerMode: false,
        focusOnSelect: true
    });

})(jQuery);


const  spyScroll = (function($){

    // Cache selectors
    var lastId,
        topMenu = $(".third-level-nav"),
        topMenuHeight = topMenu.outerHeight()+15,
        // All list items
        menuItems = topMenu.find("a"),
        thirdLevelMobile = $('.third-level-nav-mobile'),
        activeSecMobile = thirdLevelMobile.find('.active-section'),
        // Anchors corresponding to menu items
        scrollItems = menuItems.map(function(){
            var item = $(this).attr("href").startsWith('#') ? $($(this).attr("href")) : [];
            if (item.length) { return item; }
        });

    // Bind click handler to menu items
    // so we can get a fancy scroll animation
    menuItems.click(function(e){
        var href = $(this).attr("href");
        var label = $(this).text();

        if(!$(href).length) {
            window.location.href = href;
            return;
        }

        clickedMenuItem = true;

        var offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1;
        activeSecMobile.text(label);
        (activeSecMobile.text() == "") ? thirdLevelMobile.removeClass('show open') : thirdLevelMobile.addClass('show');

        $('html, body').stop().animate({
            scrollTop: offsetTop
        }, 500);
        e.preventDefault();

        if ( history.pushState ) {
        // href = href.substr(1);
        //history.pushState( null, null, ''+href );
        }
    });

    // Bind to scroll
    $(window).scroll(function(){
        // Get container scroll position
        var fromTop = $(this).scrollTop()+topMenuHeight;

        // Get id of current scroll item
        var cur = scrollItems.map(function(){
            if ($(this).offset().top < fromTop)
            return this;
        });
        // Get the id of the current element
        cur = cur[cur.length-1];
        var id = cur && cur.length ? cur[0].id : "";

        if (lastId !== id) {
            lastId = id;
            // Set/remove active class
            menuItems
                .parent().removeClass("active")
                .end().filter("[href=\\#"+id+"]").parent().addClass("active");

            activeSecMobile.text(id);
            (activeSecMobile.text() == "") ? thirdLevelMobile.removeClass('show open') : thirdLevelMobile.addClass('show');
        }

    });

})(jQuery);



const filterFomrMobile = (function($){

    var toggleBtn = $(".toggle-btn");
    var filterWrapper = toggleBtn.parent();

    toggleBtn.click(function(e){
        e.preventDefault();
        filterWrapper.toggleClass("filter-active");
    });

})(jQuery);


const singleBlogSlider = (function($){

    $('.single-blog-images-slider').slick({
        infinite: true,
        speed: 300,
        dots: true,
        arrows: true,
        slidesToShow: 1,
        dots: false,
        slidesToScroll: 1,
    });

})(jQuery);



const accordionItems = (function($){

    var allTextTabs = $(".accordion-item .text").hide();
    var textTabsParent = $(".accordion .accordion-item");
    $(".accordion .accordion-item h6").on('click', function(){

        var tabTextParent = $(this).parent();
        var tabText = $(this).next();

        if(tabTextParent.hasClass('active')){
            tabTextParent.removeClass('active');
            tabText.slideUp();
          }else{
            textTabsParent.removeClass('active');
            allTextTabs.slideUp();

            tabTextParent.addClass('active');
            tabText.slideDown();
          }

        return false;
    });

})(jQuery);

const tabsAccordion = (function($){
    $(".wrap-tab-links .tabs li").first().addClass("active");
    $(".wrap-tab-content").first().addClass("tab-active");
    $(".wrap-tab-links .tabs li a").click(function(e){
        e.preventDefault();

        var thisEl = $(this);
        var thisElParent = $(this).parent();
        var thisHref = thisEl.attr("href");

        history.pushState({}, "", thisHref)

        $(".wrap-tab-content").removeClass("tab-active");
        $(".wrap-tab-links .tabs li").removeClass("active")

        $(".wrap-tab-content"+thisHref).addClass("tab-active");
        thisElParent.addClass("active");

        setTimeout(function () {
            stickyTabSidebarFunc();
        }, 100)
    });

})(jQuery);

const stickyTabSidebar = (function($){




})(jQuery);


function stickyTabSidebarFunc() {


    if($('.tab-sidebar').length) {

        var currentActiveTab = $(".wrap-tab-content.tab-active");
        var window_top = $(window).scrollTop();
        var parentEl = $('.tab-active .tab-sidebar');
        var wrapAccordionH = parentEl.parent().find(".wrap-accordion").height();
        var mainEl = parentEl.find('.inner-sidebar');
        var mainElH = mainEl.height();
        var headerH = $('.main-header').height();
        var parentElTop = parentEl.offset().top;
        var footer_top = $("footer").offset().top;
        var containerPadding = +parentEl.closest('.tabs-accordion_component').css('margin-bottom').replace(/\D/g,'');

        if(currentActiveTab.find('.wrap-accordion').height() < currentActiveTab.find('.tab-sidebar .inner-sidebar').height()) {
            currentActiveTab.find('.tab-sidebar .inner-sidebar').css('position', 'initial');
            return;
        }

        if (window.innerWidth > 991) {
            if (window_top + mainElH > parentEl.offset().top + parentEl.height()) {
                mainEl.addClass('absolute').removeClass('fixed').css('top', 'initial');
            } else if (window_top >= parentElTop){
                //mainEl.addClass('fixed').removeClass('absolute').css('top', headerH);
                mainEl.addClass('fixed').removeClass('absolute').css('top', 0);
            } else {
                mainEl.removeClass('fixed absolute').removeAttr('style');
            }
        }
    }
}

$(document).scroll(function(e){
    stickyTabSidebarFunc();
});
$(window).on('load', function(){
    stickyTabSidebarFunc();
});


/*
customSelect;
mainNav;
searchBar;
scrollUp;
mixedSlider;
mixedSliderArrows;
sliderWithNav;
spyScroll;*/
