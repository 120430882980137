/*
 * Scroll.js
 *
 * @since 2022.1
 * @version 0.1.0
 * @author Dennis Gilli <dennis.gilli@acribis.ch>
 */

class scrollClass {
    constructor(el) {
        this.el = el
        this.className = this.el.getAttribute('data-scroll-class')
        this.scrollpos = window.scrollY
        this.offset = this.el.getBoundingClientRect().top + this.scrollpos
        this.hold = this.el.getAttribute('data-scroll-hold') !== 'false'

        window.addEventListener('scroll', () => this.toggleClass())
    }

    addClass() {
        this.el.classList.add(this.className)
    }

    removeClass() {
        this.el.classList.remove(this.className)
    }

    toggleClass() {
        this.scrollpos = window.scrollY
        this.offset = this.el.getBoundingClientRect().top + this.scrollpos

        if (this.scrollpos + window.innerHeight >= this.offset + 420) {
            this.addClass()
        } else if (this.hold === false) {
            this.removeClass()
        }
    }
}

// Initialized ScrollClass on all elements with the `data-scroll-class` attribute
Array.from(document.querySelectorAll('[data-scroll-class]'))
    .forEach(el => new scrollClass(el))
