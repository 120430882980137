import 'slick-carousel';
import './global.js';
import { throttle, debounce } from 'lodash';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import 'lazysizes';

import '@/scss/style.scss';
import '@/css/app.pcss';

import "./utils/scroll";
import {getScrollBarWidth} from "./utils/detect-scrollbar";

let subItemClicked = false;

// App main
const main = async () => {
    // Async load the vue module
    const {default: Vue} = await import(/* webpackChunkName: "vue" */ 'vue');
    // Async load the other modules
    const {mixin: VueClickaway} = await import(/* webpackChunkName: "vueclickaway" */ 'vue-clickaway');
    const {default: VueMq} = await import(/* webpackChunkName: "vuemq" */ 'vue-mq');
    Vue.use(VueMq, {
        breakpoints: { // default breakpoints - customize this
            xs: 576,
            sm: 768,
            md: 992,
            lg: 1200,
            'xl': Infinity
        },
        defaultBreakpoint: 'lg' // customize this for SSR
    });
    // Create our vue instance
    const vm = new Vue({
        el: '#header',
        delimiters: ['${', '}'],
        components: {
        },
        mixins: [VueClickaway],
        data: () => ({
            menuOpen: false,
            brandCollapsed: false,
            navCollapsed: false,
            subMenuCollapsed: true,
            scrollTop: window.pageYOffset || document.documentElement.scrollTop,
            searchOpen: false,
            resultsOpen: false,
            searchResults: {
                pages: null,
                news: null,
                faq: null,
            },
            searchQuery: '',
            noResults: false,
            pagination: null,
            subMenuOpen: false,
            timer: null,
        }),
        computed: {
        },
        watch: {
            menuOpen: function (val) {
                if (val) {
                    disableBodyScroll(this.$refs.mainMenu.parentNode);
                } else {
                    enableBodyScroll(this.$refs.mainMenu.parentNode);
                }

                // todo This is a side effect and should probably be refactored
                document.body.style.overflow = val ? 'hidden' : '';
            },
        },
        mounted: function () {
            this.resetMenuPadding();

            document.querySelector('#menu').addEventListener('mouseover', (e) => {
                if (e.target.tagName == 'UL' && e.target.parentNode.classList.contains('main-menu')) {
                    this.resetMenuPadding();
                }
            });

            if (this.$refs.subMenu) {
                this.$refs.mainMenu.addEventListener('mouseover', (e) => {
                    this.$refs.subMenu.style.height = e.target.closest('li.is-active') || e.target.tagName == 'DIV' ? 'auto' : 0;
                    this.resetMenuPadding();
                });

                this.$refs.mainMenu.addEventListener('mouseleave', () => {
                    this.$refs.subMenu.style.height = 'auto';
                    this.resetMenuPadding();
                });
            }

            window.addEventListener("scroll", throttle(this.onScroll, 100));
            this.onScroll();

            const trackingApp = document.getElementById('quickpac-tracking-app')
            if (trackingApp) {
                trackingApp.addEventListener('onTrackingOpen', () => {
                    const scrollBarWidth = getScrollBarWidth();
                    if (scrollBarWidth) {
                        this.$el.style.right = `${scrollBarWidth}px`
                    }
                });
                // todo - Fix 'DOMSubtreeModified' is deprecated. Consider using 'MutationObserver' instead.
                trackingApp.addEventListener('DOMSubtreeModified', debounce(() => {
                    const modal = document.querySelector('.tr-modal')
                    if (modal.offsetParent) {
                        const scrollBarWidth = getScrollBarWidth();
                        if (scrollBarWidth) {
                            this.$el.style.right = `${scrollBarWidth}px`
                        }
                    }
                }, 150))

                trackingApp.addEventListener('onTrackingClosed', () => {
                    this.$el.style.right = ''
                });
            }
        },
        methods: {
            toggleSubMenu: function () {
                this.subMenuOpen = !this.subMenuOpen;
            },

            // Pre-render pages when the user mouses over a link
            // Usage: <a href="" @mouseover="prerenderLink">
            // prerenderLink: function (e) {
                // const head = document.getElementsByTagName("head")[0];
                // const refs = head.childNodes;
                // const ref = refs[refs.length - 1];
                //
                // const elements = head.getElementsByTagName("link");
                // Array.prototype.forEach.call(elements, function (el, i) {
                //     if (("rel" in el) && (el.rel === "prerender")) {
                //         el.parentNode.removeChild(el);
                //     }
                // });
                //
                // if (ref.parentNode && e.currentTarget) {
                //     const target = e.currentTarget;
                //     const prerenderTag = document.createElement("link");
                //     prerenderTag.rel = "prerender";
                //     prerenderTag.href = target.href;
                //     ref.parentNode.insertBefore(prerenderTag, ref);
                // }
            // },
            away: function () {
                this.menuOpen = false;
                this.subMenuOpen = false;
            },
            toggle: function () {
                this.menuOpen = !this.menuOpen;
                this.subMenuOpen = false;
                this.brandCollapsed = !this.menuOpen && (this.scrollTop >= (['xs', 'sm', 'md'].some((breakpoints) => breakpoints === this.$mq) ? 150 : 250));
            },
            search: function (filter, site, page) {
                this.searchQuery = filter;

                if (filter.length >= 3) {
                    fetch('/actions/site-module/site/search?' + new URLSearchParams({
                        filter: filter,
                        site,
                        page: page ?? '1',
                    }))
                    .then(response => response.json())
                    .then(data => {
                        if (data.data) {
                            this.searchResults = data.data;
                            this.pagination = data.links.pagination;
                            this.noResults = false;
                        } else {
                            this.noResults = true;
                            this.searchResults = {
                                pages: null,
                                news: null,
                                faq: null,
                            };
                            this.pagination = null;
                        }

                        if (this.searchOpen && !['xs', 'sm', 'md'].some((breakpoints) => breakpoints === this.$mq)) {
                            setTimeout(() => {
                                this.$refs.results.parentElement.style.height = `${this.$refs.results.firstChild.offsetHeight}px`;
                            }, 1);
                        }
                    });
                } else {
                    this.noResults = true;
                    this.searchResults = {
                        pages: null,
                        news: null,
                        faq: null,
                    };
                    this.pagination = null;
                }

                if (this.searchOpen && !['xs', 'sm', 'md'].some((breakpoints) => breakpoints === this.$mq)) {
                    setTimeout(() => {
                        this.$refs.results.parentElement.style.height = `${this.$refs.results.firstChild.offsetHeight}px`;
                    }, 1);
                }
            },
            openSearch: function () {
                this.searchOpen = true;
                this.resultsOpen = true;
                enableBodyScroll(this.$refs.mainMenu.parentNode);
                disableBodyScroll(this.$refs.search);

                if (!['xs', 'sm', 'md'].some((breakpoints) => breakpoints === this.$mq)) {
                    this.$refs.mainMenu.style.width = `${this.$refs.mainMenu.offsetWidth}px`;
                }
            },
            closeSearch: function () {
                this.searchOpen = false;
                this.resultsOpen = false;
                this.searchResults = {
                    pages: null,
                    news: null,
                    faq: null,
                };
                this.pagination = null;
                this.searchQuery = '';
                this.$refs.mainMenu.style.width = '';
                this.$refs.input.value = null;
                this.$refs.results.parentElement.style.height = 0;

                clearAllBodyScrollLocks()
            },
            preventMobileClick: function (e) {
                const children = e.target.parentElement.querySelector('.child-items');

                if (!children || !['xs', 'sm', 'md'].some((breakpoints) => breakpoints === this.$mq)) {
                    return;
                }

                e.preventDefault();

                Array.from(this.$refs.mainMenu.querySelectorAll('ul .child-items'))
                    .filter(el => el !== children)
                    .forEach(el => {
                        el.style.height = '0px';
                    });

                children.style.height = children.style.height === '0px'
                    ? `${children.querySelector('ul').offsetHeight}px`
                    : '0px';

                e.target.querySelector('span').style.visibility = children.style.height === '0px'
                    ? ''
                    : 'hidden';
            },
            setMenuPadding: function (e) {
                // todo "vue-mq" breakpoint conditional could probably be refactored
                if (!['xs', 'sm', 'md'].some((breakpoints) => breakpoints === this.$mq)) {
                    const element = e.target.parentElement.querySelector('.child-items > ul');
                    this.$refs.mainMenu.style.paddingBottom = element ? `${element.offsetHeight}px` : '';

                    if (this.$refs.subMenu) {
                        this.$refs.mainMenu.parentNode.style.paddingBottom = `${this.$refs.subMenu.offsetHeight}px`;
                    }
                }
            },
            resetMenuPadding: function () {
                // todo "vue-mq" breakpoint conditional could probably be refactored
                if (!['xs', 'sm', 'md'].some((breakpoints) => breakpoints === this.$mq)) {
                    const element = this.$refs.mainMenu.querySelector('ul li.is-active .child-items > ul');
                    this.$refs.mainMenu.style.paddingBottom = element ? `${element.offsetHeight}px` : '';

                    if (this.$refs.subMenu) {
                        this.$refs.mainMenu.parentNode.style.paddingBottom = `${this.$refs.subMenu.offsetHeight}px`;
                    }
                }
            },
            onScroll: function () {
                const newScrollTop = window.pageYOffset || document.documentElement.scrollTop
                const scrollUp = newScrollTop <= this.scrollTop;

                this.scrollTop = newScrollTop <= 0 ? 0 : newScrollTop;

                if (!subItemClicked && !this.searchOpen) {
                    this.brandCollapsed = (!this.menuOpen || !['xs', 'sm', 'md'].some((breakpoints) => breakpoints === this.$mq)) && (this.scrollTop >= (['xs', 'sm', 'md'].some((breakpoints) => breakpoints === this.$mq) ? 150 : 250));
                    this.navCollapsed = !scrollUp && (this.scrollTop >= (['xs', 'sm', 'md'].some((breakpoints) => breakpoints === this.$mq) ? 150 : 250));
                    this.subMenuCollapsed = !this.brandCollapsed;
                }

                if (this.$refs.scrollspy) {
                    const section = document.querySelectorAll("section[id]");
                    const sections = {};

                    Array.prototype.forEach.call(section, function(e) {
                        sections[e.id] = e.offsetTop;
                    });

                    const scrollPosition = (document.documentElement.scrollTop || document.body.scrollTop) + 50;

                    Array.from(this.$refs.scrollspy.querySelectorAll('.font-medium')).forEach(el => {
                        el.classList.remove('font-medium');
                    })

                    Object.entries(sections).forEach(([key, value], i) => {
                        if (value <= scrollPosition && scrollPosition < sections[Object.keys(sections)[i + 1]]) {
                        console.log(this.$refs.subMenu.querySelector('a[href*=' + key + ']'))
                            Array.from(this.$refs.subMenu.querySelectorAll('a'))
                                .forEach(el => el.classList.remove('font-semibold'));
                            this.$refs.subMenu.querySelector('a[href*=' + key + ']').classList.add('font-semibold');

                            this.$refs.scrollspy.querySelector('a[href*=' + key + ']').classList.add('font-medium');
                            this.$refs.scrollspyReplace.innerText = this.$refs.scrollspy.querySelector('a[href*=' + key + ']').getAttribute('data-hover-fix-semibold');
                        }
                    })
                }

                if(this.timer !== null) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(function() {
                    subItemClicked = false;
                }, 150);
            },
            clickSubItem: function () {
                subItemClicked = true;
            }
        },
    });

    // todo should be refactored into table module
    const cells = document.querySelectorAll('figure.table th, figure.table td');
    Array.from(cells).forEach(cell => {
        let styles = [];

        cell.innerHTML = cell.innerHTML.replace(/(.*){br}(.*)/gm, (match, p1, p2) => {
            return `<div>${p1}</div><div>${p2}</div>`;
        });

        cell.innerHTML = cell.innerHTML.replace(/^(.*){(.*)}/, (match, p1, p2) => {
            styles = p2.split(',');
            return p1;
        });

        styles.forEach((style, i) => {
            if (style.startsWith('highlight')) {
                const color = style.split(':')[1];

                if (color && color.startsWith('#')) {
                    cell.style.backgroundColor = color;
                } else if (color) {
                    styles[i] = `highlight-${color}`;
                }
            }
        });

        if (styles) {
            cell.classList.add(...styles);
        }
    });

    // Globally init slick slider for `[data-slick]` attributed divs
    const slickSliders = document.querySelectorAll('[data-slick]');
    Array.from(slickSliders).forEach(el => {
        const customPrevArrow = el.querySelectorAll('.slick-prev')
        const customNextArrow = el.querySelectorAll('.slick-next')
        const config = {}
        if (customPrevArrow) {
            config.prevArrow = customPrevArrow
        }
        if (customNextArrow) {
            config.nextArrow = customNextArrow
        }
        return $(el).slick(config)
    });

    document.body.style.visibility = "visible";

    return vm;
};

// Execute async function
main().then(() => {
    console.log();
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}
